@import 'scss/variables.scss';

.priorityListItem svg{
    width: 18px;
    height: 18px;
}

.prior-1,.priority1 {
    color: $prior1-color;
}

.prior-2,.priority2 {
    color: $prior2-color;
}

.prior-3,.priority3 {
    color: $prior3-color;
}

.prior-4,.priority4 {
    color: $prior4-color;
}