.task-completed-task-modal {
    opacity: 0.7;
    span {
        position: relative;
        display: inline-block;
        font-size: 16px;
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 50%;
            top: 10%;
            left: 0;
            border-bottom: 1px solid #000;
            /* Adjust the color and style as needed */
        }
    }
}