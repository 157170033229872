.taskScheduler{
    width: 250px;
    max-height: 800px;
}
.set-project__popper--overlay,
.set-schedule__popper--overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}
.set-project__popper,
.set-schedule__popper {
  padding: 0.4rem 0;
  position: absolute;
  background-color: #282828;
  width: 14rem;
  border-radius: 3px;
  box-shadow: 0 10px 20px #00000030, 0 6px 6px #0000003b;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  font-size: 13px;
  font-weight: 400;
  &--option {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.4rem;
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
    &-icon {
      width: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }
  }
}
.set-new-task__project,
.set-new-task__schedule {
  cursor: pointer;
  color: #ffffff99;
 font-size: 14px;
  &--weekday {
    margin-left: auto;
    color: #31313199;
  }
}
.set-project__project {
  top: 2.2rem;
  left: -6rem;
}

.set-schedule__popper {
  top: 2.2rem;
  left: 0rem;
  &--option {
    cursor: pointer;
  }
}
.scheduler-icon{
    width: 24px;
    height: 24px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  position: absolute;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 8px rgba(0,0,0,.12);
  /* Use the provided top and left values for positioning */
}
.modal-overlay-popper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-popper {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  // position: absolute;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 8px rgba(0,0,0,.12);
  /* Use the provided top and left values for positioning */
}
.selectedPrior{
  background-color: #e0e0e0;
  border-radius: 5px;
}
/* Adjust the width of the calendar */
.custom-datepicker-container {
  height: 230px; 
  overflow: auto;
}