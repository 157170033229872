@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:wght@200;300;400&display=swap");
@import "../../scss/mixins.scss";
@import '../../scss/variables.scss';
.landing-page {
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  color: #575757;
  font-family: 'Poppins', sans-serif !important;
  width: 100%;
  position: relative;
  .intro__header {
    width: 100%;
    height: 5.5rem;
    justify-content: center;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 4;
    padding: 0 3rem;
    box-shadow: 0px 2px 6px 2px #a1a1a145;
  }

  .intro__nav {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    align-items: center;
    flex: 1 1;
    min-height: 3rem;
    margin: 0 auto;
    // max-width: 1088px;
    padding: 0 1rem;
    @include respond(tab-land) {
    }
    &--group {
      display: flex;
      align-items: center;

      &__wrapper {
        align-items: center;
        display: flex;
        &:nth-child(2) {
          @include respond(tab-land) {
            display: none;
          }
        }
      }
    }
    &--item {
      height: 3rem;
      display: flex;
      place-items: center;
      cursor: pointer;
      padding: 0 1rem;
      font-size: 1rem;

      a {
        height: -webkit-fill-available;
      }

      &:last-of-type {
       
      }
    }
    &--link:hover {
      color: #1f1f1f;
      border-bottom: $primary-color 1px solid;
      background-color: #fafafa;
    }

    &--cta {
      color: #fff;
      background-color: $primary-color;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0;
      font-family: "Graphik-semibold";
      padding: 0 1rem;
      min-height: 2.5rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
    }
  }

  .nav-logo {
    margin-right: 1rem;
    .logo__desktop {
      width: 60px;
      @include respond(phone) {
        display: none;
      }
    }
  }
}
.logo__mobile {
  display: none;
  @include respond(phone) {
    display: block;
    width: 32px;
    height: 32px;
  }
}

.section__intro {
  position: relative;
  overflow: hidden;
  text-align: center;
  white-space: pre-line;
  @include respond(tab-port) {
    height: 70vh;
  }
  .intro__hero {
    padding: 8rem 1rem 0;
    -webkit-margin-after: -10rem;
    position: relative;
    -webkit-margin-before: 3rem;
    margin-block-start: 3rem;
    z-index: 2;
    white-space: pre-line;
    text-align: center;
    @include respond(phone) {
      // padding-top: 200px;
      padding: 4rem 1rem 0;
    }
    &--cta {
      color: #fff;
      background-color: $primary-color;
      font-size: 1rem;
      line-height: 1.25;
      letter-spacing: 0;
      font-family: 'Poppins', sans-serif !important;
      padding: 0.2rem 1rem;
      min-height: 2.5rem;
      border-radius: 8px;
      margin-block-start: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $primary-color;
      min-width: 100px;
    }

    &--illustration-1 {
      display: block;
      margin: 0 auto;
      inline-size: 90%;
      max-inline-size: 1256px;

      @include respond(tab-port) {
          margin-bottom: 0;
          right: 50%;
            transform: translateY(50%);
            }
    }
  }

  .hero-text {
    font-size: 3rem;
      line-height: 1.3;
    letter-spacing: -0.04em;
    font-weight: 600;
    text-align: center;
    font-family: 'Poppins', sans-serif !important;
    color: #1f1f1f;
    //width: 40%;
    text-align: center;
    margin: auto;
    max-inline-size: 46.8rem;
    margin-block-end: 1rem;
    letter-spacing: -0.02em;
    @include respond(tab-land) {
      width: 50%;
      font-size: 60px;
    }
    @include respond(tab-port) {
      width: 80%;
    }
    @include respond(phone) {
      font-size: 30px;
      width: 90%;
    }
  }

  .hero-text__sub {
    line-height: 1.5;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: -0.01em;
    margin-block-start: 0.83rem;
    margin-left: auto;
    margin-right: auto;
    margin-block-end: 1.5rem;
    max-inline-size: 480px;
    -webkit-margin-after: 24px;
    color: #1f1f1f;

    @include respond(phone) {
      font-size: 1rem;
    }
  }
}
.fade-anim img {
  animation: fadeAnim 0.5s ease-out;
}
@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

 .scroll-container {
  overflow: hidden;
  position: relative;
}

.scroll-content {
  display: flex;
  gap: 4rem; 
  animation: scroll 20s linear infinite;  
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
 
  
 
 
.slidingImg{
object-fit: cover;
object-position: center;
width: 200px;
 
}