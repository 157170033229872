
.upcomming-top-nav-btns{
    font-size: 21px;
    color: black;
    padding-right: 8px;
    &:hover{
        background: #e0e0e0;
    }
    &:disabled{
        cursor: not-allowed;
        color: rgb(53, 51, 51);
        &:hover{
            background-color: none;
        }
    }
}
.top-calender-date li{
    &:hover{
        background-color: #e5e5e5;
    }
// color: #579dff;
}
.day-section-tasks{
    // max-height: 380px;
    // &:hover{
    //     overflow: auto;
    // }
}
.add-task-section{
    // position: sticky;
    // bottom: 0;
    // top: 0;
    // overflow: hidden;
}