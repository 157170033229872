@import"/src/scss/variables.scss";
.search__result__task{
    cursor: pointer;
    &:hover{
        background-color: #eeeeee;
        font-size: 21px;
        border-left: 2px solid transparent;
        border-color: $primary-color;
    }
}
.search_check_box{
    padding-left: 15px;
    margin-left: 8px;
}