.custom-datepicker-container{
    .month p span{
        // margin: 0;
        font-size: 16px !important;
        color: black !important;
    }
    .month-title{
        margin: 0 !important;
        color: black !important;
    }
    .mobile-datepicker .month ul.date li{
        margin-bottom: 0;
    }
    .mobile-datepicker .month{
        margin-bottom:0
    }
}