.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.toast.error {
  background-color: #e74c3c;
}

.toast.success {
  background-color: #1c8129;
}

.toast button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
}

