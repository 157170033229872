/* src/main.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
 
  @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500;800&family=Poppins:wght@300;400;700&family=Roboto:wght@400;500;700&display=swap');
 
p {
  margin: unset;
 }
ul {
  all: unset;
}

li {
  list-style: none;
}

* ~ input:focus {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #8b8b8b;
}

@media only screen and (max-width: 56.25em) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}
button {
  border: unset;
  border-radius: 5px;
  cursor: pointer;
  background-color: unset;
}

a {
  text-decoration: unset;
  color: inherit;
}

/* Todo: create appropriate file for this */
button.action {
  border-radius: 5px;
  font-weight: 600;
  padding: 0.6rem;
  max-width: 100%;
  border-radius: 5px;
  transition-duration: 0.3s;
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
   font-family: 'Noto Serif', serif!important;
}
button.action:not(:last-child) {
  margin-right: 0.4rem;
}
button:disabled {
  opacity: 0.5;
}

button.action__add-task,
button.action__add-project,
.action__delete-project {
  background-color: #de4c4a;
  color: #feffff;
}
button.action__cancel {
  background-color: #f5f5f5;
}
button.action__cancel--dark {
  background-color: #292929;
  color: #feffff;
}
.action__add-project {
  padding: 6px 12px 7px !important;
}

