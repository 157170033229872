 
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed; 
    background: #0000005c;
    z-index: 999999;
    width: 100%;
    top: 0;  
    left: 0;  
    right: 0; 
    bottom: 0;  
    overflow: hidden;  
  }
  
  .loading-spinner {
      width: 150px;
    height: 150px;
     margin-bottom: 10px;
  }
  
   