.AddWorkSpace {

  .workModal {
    width: 50vw;
  }
}

.favorite-toggle {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 20px;
}

.favorite-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.color-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.btn {
  display: inline-flex;
  padding: 10px;
  position: relative;
  text-align: center;
  transition: background 600ms ease, color 600ms ease;
  justify-content: center;
  width: 50%;
}

input[type="radio"].toggle {
  display: none;

  &+label {
    cursor: pointer;
    min-width: 60px;

    &:hover {
      background: none;
      color: #1a1a1a;
    }

    &:after {
      background: #1a1a1a;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      /* Initialize the left position */
      transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
      width: 100%;
      z-index: -1;
    }
  }

  &.toggle-left+label {
    border-right: 0;

    &:after {
      left: 100%;
    }
  }

  &.toggle-right+label {
    margin-left: -5px;

    &:after {
      left: -100%;
    }
  }

  &:checked+label {
    cursor: default;
    color: #1a1a1a;
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px #00000017;
    transition: color 200ms;
    border-radius: 10px;

    &:after {
      left: 0;
    }
  }
}