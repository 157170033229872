@import 'scss/variables.scss';
@import "../../scss/mixins.scss";
.auth-page {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: 'Poppins', sans-serif;
    color: #000;

   &__container {
    min-width:100%;
     @include respond(phone) {
      max-width: 100%;
      margin: 0;
      border: unset;
      border-radius: 0;
      height: 100%;
      min-width: unset;
      width: 100%;
    }
  }

  &__frame {
  
    @include respond(phone) {
      margin: unset;
      height: 100vh;
    }
  }

  &__logo {
    text-align: left;
    margin: 1em 1em;
    display: flex;
    justify-content: center;
  }
  &__content {
    box-sizing: border-box;
    color: #000;
    font-size: 13px;
    margin: auto;
 
    @include respond(phone) {
      width: unset;
      max-width: unset;
    }
  }
  h1 {
    color: #000;
    font-size: 20px;
    margin: .5em 0;
    padding: 0;
    font-weight: bold;
    text-align: center;
  }
  .field {
    margin-bottom: 10px;
  }
  .label {
     font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-left: unset;
  }
  input {
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    padding: 5px;
    width: 100%;
    background: #f3f3f3;
    min-height: 48px;
  }
  form {
    margin-top: 30px;
  }
  hr {
    background-color: #ddd;
    border: 0;
    color: #ddd;
    height: 1px;
    margin: 1em 0;
    padding: 0;

  }

  .google-auth,
  .auth-button {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 13px !important;
    font-weight: 400;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
    color: #202020;
    margin-bottom: 14px;
    background: #fff;
    border: 1px solid #ddd;
    text-shadow: none;
    padding: 6px !important;
    line-height: 24px;
    min-width: 273px;
    border-radius: 3px;
    @include respond(phone) {
      min-width: unset;
      & span {
        text-overflow: ellipsis;
        width: fit-content;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
      }
    }
    &:hover {
      background-color: #eee;
      box-shadow: none;
    }
    img {
      margin: 0 8px 0 0 !important;
    }
  }
  .separator {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #ddd;
    height: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    position: relative;

    .middle_separator {
      color: #777;
      font-size: 13px;
      left: 41%;
      top: -9px;
      padding: 0 16px;
      position: absolute;
      text-shadow: 0 1px 0 #fff;
      text-transform: uppercase;
      background: #fff;
    }
  }
  .toggle_password {
    position: relative;
    input[type="password"] {
      padding-right: 40px;
      width: 100%;
    }
    .toggle {
      border-radius: 3px;
      cursor: pointer;
      height: 24px;
      margin-top: -12px;
      position: absolute;
      right: 8px;
      top: 50%;
      width: 24px;
    }
  }

  .submit-button {
    background: 0;
    background-color: $primary-color;
    color: #fff !important;
    text-shadow: none;
    border: 1px solid transparent;
    font-weight: 700;
    position: relative;
    display: flex;

    &:disabled {
      opacity: 0.7;
    }

    &:hover {
      border-color: #0375fff7;
      background-color: #60a5faf7;
      box-shadow: 0 1px 2px #00000026;
    }
  }

  p {
    margin: 10px;
    text-align: center;
    a {
      color: #dd4b39;
      text-decoration: none;
    }
  }
  .has-error {
    border-color: #dd4b39;
  }
  .error-message {
    color: #dd4b39;
    display: flex;
    padding: 0.5em 0;

    svg {
      margin-right: 3px;
    }

    .error-block {
      margin-bottom: 1em;
    }
  }
}

.signup {
  overflow-x: hidden;
  color: #000;
  font-size: 13px;
  margin: 1em ;
  
  // @include respond(phone) {
    //   width: 100%;
    // }
  &.step_one &__step-one {
    animation-name: visible;
  }

  & .step_one {
  }
  &.in_step_two .signup__wrapper {
    animation-name: step-two;
  }
  &.in_step_one .signup__wrapper {
    animation-name: step-one;
  }
  & .signup__wrapper,
  & .step_one,
  & .step_two {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  & .step_one,
  & .step_two {
    box-sizing: border-box;
    max-width: 100%;
    min-width: 100%;
    padding: 0 5rem;
  }

  & .step_two {
    visibility: hidden;
  }
  &.in_step_two .step_two {
    animation-name: visible;
  }

  &.in_step_two .step_one {
    animation-name: hidden;
  }

  &.in_step_one .signup_wrapper {
    animation-name: step-one;
  }
  &.in_step_two .signup_wrapper {
    animation-name: step-two;
  }
  &__wrapper {
    display: flex;
    position: relative;
  }

  .backlink {
    align-items: center;
    color: #000;
    display: inline-flex;
    margin-bottom: 1em;
    padding: 0 0.5em;
    cursor: pointer;

    &:hover {
      background-color: #eee;
      border-radius: 3px;
      text-decoration: none;
    }
    svg {
      margin-left: -10px;
    }
  }

  .hint-text {
    margin-top: 0.5em;
    margin-bottom: 1em;
    color: #666;
  }

  .ring__spinner{
    margin-left: 2em;
    margin-top: -10px;
    position: absolute;
    top: 50%;
  }
}

@keyframes visible {
  0% {
    visibility: hidden;
  }

  to {
    visibility: visible;
  }
}

@keyframes hidden {
  0% {
    visibility: visible;
  }

  to {
    visibility: hidden;
  }
}

@keyframes step-two {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
@keyframes step-one {
  0% {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}
 .image-preview {
  max-width: 100%;  
  max-height: 200px; 
  margin-top: 10px;  
}
 
.nsm7Bb-HzV7m-LgbsSe.hJDwNd-SxQuSe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK {
  width: 350px!important;
  margin:0 auto !important;
}
.sideImg {
  position:fixed !important;
  width: 50%;
}
.sideImg img {
  width: 90%;
  margin: 10px auto;
  display: flex;
  align-items: flex-end;
}

.OtpScreen {
  margin: 10% 0
}