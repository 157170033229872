.settingsModal{
    width: 50vw;
    height: 80%;
    overflow-y: auto;
    box-shadow: 0 15px 50px 0 rgba(0,0,0,.35);
    
}

   .active {
    background-color: #e6edf5;  

   }
  
     /* Style for the profile container */
     .profile-container {
        display: flex;
        align-items: center;
    }

     .profile-photo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 20px;
    }
 
     .file-input {
        display: none;
    }