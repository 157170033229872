 
  @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500;800&family=Poppins:wght@300;400;700&family=Roboto:wght@400;500;700&display=swap');
 
 
:root {
  --primary-color: #579dff;
  --primary-link-color: #579dff;
  --primary-text-color: #2c3f59;
  --lighter-background-color: #e3e3e382;
  --white-color: #fff;
  --primary-box-shadow-color: #0000005e;
  --primary-font-family: "Poppins", sans-serif;
  --secondary-font-family: 'Roboto', sans-serif;;
  --primary-font-size: 14px;
  --circle-border-radius: 50%;
}

body {
  margin: 0;
  font-family: var(--primary-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--primary-font-size);
  font-weight: 400;
  overflow-x: hidden;
  color: var(--primary-text-color);
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
}
.customOverlay {
  background: rgba(0, 0, 0, 0.5);
}
.fixed-height {
  height: calc(100vh - 100px);
  overflow: hidden;
}
.fixed-sidebar {
  height: calc(100vh - 230px);
  overflow: scroll;
}

.svgIco {
  font-size: 1.5em;
  color: #2c3f59;
  margin-right: 0.5em;
}
.workspacebtn {
  display: flex;
  align-items: center;
  padding: 5px 30px;
  cursor: pointer;
  /* border-top:1px solid #2c3f59; */
}
.authstyle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
 .divsvg svg{
width: 100px!important;
}

.profileImage {
  background: #579dff;
  text-transform: uppercase;
  font-size: medium;
  border-radius: 50%;
  font-weight: 600;
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
}
.welcome {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-left: 10px;
  width: 70%;
}
.menu-options {
  position: absolute;
  top: calc(55px + 10px);
  width: 240px;
  border-radius: 10px;
  background-color: var(--white-color);
  left: 10px;
}
.menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.Sidebar {
  background-color: var(--lighter-background-color);
  width: 425px;
  padding: 12px;
}

.Sidebar {
  background-color: var(--lighter-background-color);
  width: 425px;
  padding: 12px;

  .App-header {
    .menu-options {
      position: absolute;
      top: calc(55px + 10px);
      width: 240px;
      border-radius: 10px;
      background-color: var(--white-color);
      left: 10px;

      .menu-item {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: #efefef;
        }
        .svgIco {
          font-size: 1.5em;
          color: #2c3f59;
          margin-right: 0.5em;
        }
      }

      .menu-item {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: #efefef;
        }
        .svgIco {
          font-size: 1.5em;
          color: #2c3f59;
          margin-right: 0.5em;
        }
      }

      .menu-item-disabled {
        display: flex;
        align-items: center;
        padding: 10px;
        color: #d1d8e0;
        cursor: not-allowed;
        &:hover {
          background-color: #efefef;
        }
        .svgIco {
          font-size: 1.5em;
          color: #d1d8e0;
          margin-right: 0.5em;
        }
      }
    }

    .welcome {
      display: flex;
      text-align: left;
      flex-direction: column;
      margin-left: 10px;
      width: 70%;
    }
    .profileImage {
      background: var(--primary-color);
      text-transform: uppercase;
      font-size: medium;
      border-radius: var(--circle-border-radius);
      font-weight: 600;
      display: flex;
      width: 38px;
      height: 38px;
      justify-content: center;
      align-items: center;
      color: var(--white-color);
    }
  }
  .sidebar-menu {
    margin: 1em 0em 2em;
  }
}
.AddTaskBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 0px 0 #5192ed;
  background-color: var(--primary-color);
  border-radius: 14px;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px ;
  text-decoration: none;
  width: 100%;
  margin: 1.5em 0;
  &:hover {
    background-color: #4c92f3;
  }
  &:active {
    position: relative;
    top: 1px;
  }
}
.popover {
  background-color: #fff;
  position: absolute;
  left: 25%;
  top: 40px;
  padding: 12px 9px;
  box-shadow: 0px 0px 6px 1px #0000005e;
  border-radius: 8px;
  z-index: 9;
  font-size: 13px;
}

.project-options {
  background-color: #fff;
  position: absolute;
  right: 0;
  left:unset;
  padding: 12px 9px;
  box-shadow: 0px 0px 6px 1px #0000005e;
  border-radius: 8px;
  z-index: 9;
  font-size: 13px;
  width: max-content;
}
.badge__danger,.badge__success {
  margin: 0 8px;
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  color: #fff;
  font-weight: 800;
  border-radius: 50%;
  font-size: 12px;
}

.badge__danger{
  background: #ff291a;

}
.badge__success{
  background: #21c013;
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin: 1em 0;
}

 
.favourites-list-btn {
    display: flex;
     justify-content: space-between;
    width: 100%;
    margin: 2em 0px;
    cursor: pointer;
  }
  div#my-tooltip {
    padding: 3px 6px !important;
    font-size: 12px !important;
    border-radius: 6px;
    font-weight: 500;
}

 
.modal-popper.priorityModal.rounded-md {
    max-height: 250px;
    overflow-y: scroll;

}
 .project-link {
  background-color: blue;
  color: white;
  text-decoration: none;
  display: block;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
}

 .project-link:hover {
  background-color: white;
  color: blue;
}

 .project-link:active {
  background-color: blue;
  color: white;
}
.reschedule{
  position:absolute;
  right:4%

}
.fields{
  display: flex;
  flex-direction: column;
   align-self: center;
}
@media screen and (min-width: 370px) and (max-width: 500px) {
  .wraping{
flex-wrap: wrap;
  }
  .mobile{
    justify-content: flex-start!important;

  }
  .Sidebar {
     width: 380px;
    padding: 8px;
  }
  .welcome{
     margin-left: 3px;
  }
  .task__text{
    font-size: 12px;
    line-height: 16px;
  }
  span.prior.text-black {
    text-align: left;
}
.signup .step_one, .signup .step_two{
  padding:2px
}
.auth-page{
  width: 96%;
}
.workspacebtn {
  padding: 5px 10px;
}
.flex-between{
  flex-wrap: wrap;
}
.reschedule{
  position: static;

}
.AddWorkSpace .workModal {
  width: 90vw;
}
.settingsModal {
  width: 95vw;
  height: 50%;
}
}