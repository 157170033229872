 .task {
   background-color: transparent;
   box-shadow: unset;
   border-color: #0000001f;

   &:hover {
     border-color: rgba(0, 0, 0, 0.2);
   }



   .task__date {
     color: unset;
   }

   &__project-info {
     color: #555;
   }
 }

 .empty-state {
   &__header {
     color: #202020;
   }

   &__body {
     color: #777;
   }
 }

 @import "scss/mixins.scss";
 @import "scss/variables.scss";


 .task {
   position: relative;
   min-height: 1rem;
   width: -webkit-fill-available;
   display: flex;
   flex-direction: row;
   padding: 0.5rem 0;
   align-items: flex-start;
   border-bottom: 1px solid #ffffff1a;

   &:first-of-type {
     border-top: 1px solid #ffffff1a;
   }

   &:hover {
     border-color: #ffffff33;

     .menu__trigger {
       opacity: 1;
     }
   }

   @include respond(tab-port) {}

   &-list__wrapper {
     padding-left: 55px;
     padding-right: 55px;
     max-width: 800px;
     margin: 0 auto;
   }

   &__checkbox {
     width: 18px;
     border: grey 1px solid;
     height: 17px;
     border-radius: 50%;
     display: flex;
     place-items: center;
     justify-content: center;
     margin-right: 0.6rem;
     cursor: pointer;

     &--icon {
       opacity: 0;

       &:hover {
         opacity: 1;
       }
     }
   }

   &__details {
     font-size: 14px;
     width: 100%;
   }

   &__info {
     display: flex;
     //  justify-content: space-between;
     // justify-content: space-between;

   }

   &__text {
     color: #0c0b0b;
     margin-bottom: 4px;

     -webkit-line-clamp: 4;
     -webkit-box-orient: vertical;
     display: -webkit-box;
     flex-grow: 1;
     font-size: 14px;
     line-height: 21px;
     overflow: hidden;
     overflow-wrap: break-word;
     padding-bottom: 2px;
     word-break: break-word;
   }

   &__description {
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     display: -webkit-box;
     flex-basis: 100%;
     font-size: 12px;
     line-height: 18px;
     margin-bottom: 2px;
     overflow: hidden;
     overflow-wrap: break-word;
     word-break: break-all;
   }

   &__date {
     font-size: 12px;
     line-height: 12px;
     display: flex;
     align-items: center;
     color: #0c0b0b;

     svg {
       margin-right: 2px;
     }
   }

   &__project-info {
     display: flex;
     font-size: 12px;
     color: #0c0b0b;
     align-items: center;
   }
 }

 .empty-state {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;

   &__illustration {
     width: 220px;
     height: 200px;
   }

   &__header {
     font-size: 16px;
     color: #0c0b0b;
     margin: 8px 0;
   }

   &__body {
     color: #ffffff99;
     font-weight: 100;

     @include respond(tab-port) {
       text-align: center;
     }
   }

   &__button {
     background-color: $primary-color;
     //dark theme
     color: hsla(0, 0%, 100%, 0.87);
     display: block;
     font-size: 13px;
     line-height: 17px;
     margin: 20px auto 0;
     padding: 6px 12px 7px;
     text-decoration: none;
     border: 1px solid transparent;
     border-radius: 3px;
     color: #fff;
     cursor: pointer;
   }
 }

 .date__today {
   color: #058527 !important;
 }

 .date__tomorrow {
   color: #ff9a14 !important;
 }

 .date__next-week {
   color: #692fc2 !important;
 }

 .date__weekend {
   color: #246fe0 !important;
 }

 .date__overdue {
   color: #dd4b39 !important;
 }

 .date__sunday {
   color: #dd0d7f !important;
 }

 .add-task {
   margin: 0.4rem 0;

   // & .quick-add__wrapper {
   //   background-color: ;
   // }
   background-color: inherit;

   &__container {
     //width: 100%;
     border: 1px solid #383838;
     padding: 10px;
     margin-bottom: 1rem;
     border-radius: 10px;
     cursor: text;
   }

   &__input {
     width: -webkit-fill-available;
     margin-bottom: 1rem;
     font-size: 12px;
     height: 1.6rem;
     border: none;
     font-weight: 600;
     background-color: transparent;
     color: #ffffde;
     border-radius: 5px;

     &:focus {
       outline: unset;
     }
   }

   &__attributes {
     display: flex;
     justify-content: space-between;

     &--left,
     &--right {
       display: flex;
       flex-direction: row;
       align-items: center;
     }
   }

   &__actions {
     background-color: inherit;

     button {
       &:not(:last-child) {
         margin-right: 0.4rem;
       }
     }

     &--add-task {
       background-color: #de4c4a;
       color: #feffff;
     }

     &--cancel {
       background-color: #f5f5f5;
       border: 1px solid #555;
     }
   }

   &__toggle {
     display: flex;
     align-items: center;
     cursor: pointer;
     color: #808080;
     margin-top: 0.6rem;

     &:hover {
       color: #de4c4a;

       .add-task__icon {
         background-color: #de4c4a;

         svg {
           stroke: #feffff;
         }
       }
     }
   }

   &__icon {
     border-radius: 50%;
     margin-right: 0.4rem;
     display: flex;
     place-items: center;

     svg {
       stroke: #de4c4a;
     }
   }
 }

 .set-new-task__project,
 .set-new-task__schedule {
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 6px;
   border: 1px #555 solid;
   border-radius: 5px;
   font-size: 12px;
   position: relative;

   &:not(:last-child) {
     margin-right: 0.4rem;
   }

   svg {
     margin-right: 0.2rem;
   }
 }

 .set-new-task__label {
   margin: 0 0.4rem;
 }

 .quick-add__container {
   margin-bottom: unset !important;
   border-bottom: unset;
 }

 .action-icons {
   font-size: 21px;
   color: gray;
 }

 .display-action-icons {
   font-size: 18px;
   cursor: pointer;
   color: #666;
   padding: 4px;

   &:hover {
     background: #e4e4e7;
     border-radius: 8px;
   }
 }

 .cancel-button {
   background: #eee;
   padding: 5px 20px;
   color: #323030;
   font-weight: 600;
   text-transform: capitalize;
   font-size: 16px;

   &:hover {
     background: #dbdada;
   }
 }

 .confirm-danger-button {
   background-color: $prior1-color;
   padding: 5px 20px;
   font-size: 16px;
   font-weight: 600;
   text-transform: capitalize;
   color: #fff;

   &:hover {
     background: #e9756d;
   }

 }

 .menu-item-styles {
   padding: 4px;

   &:hover {
     background-color: #f5f5f5;
     border-radius: 10px;
   }
 }

 .userpick {
   &__letter {
     font-size: 14px;
     width: 18px;
     height: 18px;
     background-color: #246fe0;
     color: #fff;
     // padding: 10px;
     border-radius: 50%;
     text-transform: uppercase;
   }

   &__username {
     font-size: 16px;
     color: #2e2e2e;
   }
 }

 .projectWorkspace-assignee-pic {
  height: 28px;
  width: 28px;
   display: block;
 }
 .projectWorkspace-assignee-pic img{
  object-fit: cover;
border-radius: 50%;
width: 45px;
height: 28px;
 }