$midnight: #2c3e50;
$clouds: #ecf0f1;

// General
input#chck1 {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

// Layout
.row {
    display: flex;

    .col {
        flex: 1;

        &:last-child {
            margin-left: 1em;
        }
    }
}

/* Accordion styles */
.tabs {
    overflow: hidden;
}

.tab {
    width: 100%;
    // color: white;
    overflow: hidden;
    &-label {
        display: flex;
        justify-content: space-between;
        padding: 1em 0;
        // background: $midnight;
        color: #2c3f59;
        font-weight: bold;
        cursor: pointer;
        // &::before {
        //     content: "\276F";
        //     width: 1em;
        //     height: 1em;
        //     text-align: center;
        //     transition: all .35s;
        // }
    }

    &-content {
        max-height: 0;
        padding: 0 1em;
        color: $midnight;
        background: white;
        transition: all .35s;
    }

    &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        // background: $midnight;
        cursor: pointer;

        // &:hover {
        //     background: darken($midnight, 10%);
        // }
    }
}
.accordainIcon{
    &::before {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all .35s;
        padding: 6px;
        border-radius: 5px;
    }
}

// :checked
input#chck1:checked {
    +.tab-label .accordainIcon{
            transform: rotate(90deg);
            transition: all .35s;
    }
    ~.tab-content {
        // max-height: 100vh;
        max-height: fit-content;
        padding: 1em 0;
    }
}