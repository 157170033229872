@import 'scss/variables.scss';

.priority1_flag {
    border: 2px solid $prior1-color-light;
    border-radius: 10%;
}
.priority2_flag {
    border: 2px solid $prior2-color-light;
    border-radius: 10%;
}
.priority3_flag {
    border: 2px solid $prior3-color-light;
    border-radius: 10%;
}
.priority4_flag {
    border: 2px solid $prior4-color-light;
    border-radius: 10%;
}
.task__details.Task-completed{
    opacity: 0.7;
    .task__text a,.task__text{
        position: relative;
        display: inline-block;
        font-size: 14px;
        &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 50%;
            top: 10%;
            left: 0;
            border-bottom: 1px solid #000; /* Adjust the color and style as needed */
        }
    }
}
.task_label_info{
    display: flex;
}