table.table-user-information tr td:first-child {
  padding-right: 13px;
  font-weight: 700;

}

table.table-user-information tr td.second-child {
  color: #579dff;
  font-size: 16px;
  font-weight: 600;

}

table.table-user-information tr {
  margin-bottom: 15px;
}
.admin-user-profileimage{
  width: 150px !important;
  height: 150px !important;


}