.counter {
  background-color: #579dff;
  padding: 20px 0;
  border-radius: 5px;
  margin-right: 18px;
  color: #fff;
}

.count-title {
  font-size: 40px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.count-text {
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.DisplayIconDashBoard {
  margin: 0 auto;
  float: none;
  display: table;
  font-size: 26px;
  // color: #4ad1e5;
}
