.light .sidebar {
  background-color: #fafafa;
  .custom-project,
  .project-group {
    &:hover,
    &.active {
      background-color: #93C5FD;
    }
  }
}

.light .custom-project-group__add-project {
  svg{
    stroke: #333;
  }
  &:hover {
    background-color: #93C5FD;
  }
}

