$primary-color: #579dff;
$primary-color-hover:#7999c7;
$font-size: 16px;
$prior1-color:#d1453b;
$prior2-color:#eb8909;
$prior3-color:#246fe0;
$prior4-color:#666;
$prior1-color-light:#ffc9c5;
$prior2-color-light:#ffdbad;
$prior3-color-light:#c5dcff;
$prior4-color-light:#666;